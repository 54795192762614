import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import axios from "axios";
import { toast } from "react-toastify";
import { logoGif } from "../../assest";
import { useNavigate } from "react-router-dom";

export const ModalSection = ({open,onCloseModal}) => {
  const naviagte=useNavigate();

    const [loading, setLoading]=useState(false);
    const [errors, setErrors]=useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        // message: "",
        phone: "",
      });


      const onSubmitFunc= async(e)=>{
        
        e.preventDefault();

           console.log("formData",formData);
     if(formData.phone.length < 9) {
      setErrors(true);
     }else{


      const currentUrl = window.location.href;

      const protocol = window.location.protocol;  // "https:"
      const hostname = window.location.hostname;  // "logoaura.com"
      
      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`
      const queryStringFormData = new URLSearchParams(formData).toString()


      let finalReq = {
        ...formData,
        source: `https://logoaura.com/${queryStringFormData}`,
        domain: baseUrl,
        lead_url: baseUrl,
        url: `https://logoaura.com/${queryStringFormData}`,
      };

      try {
        setLoading(true)
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=594696';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
  
        const formDataString = new URLSearchParams(finalReq).toString();
  
        const response = await axios.post(apiUrl, formDataString, config);
        if(response.status == 200){
            // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id)
          const queryString = new URLSearchParams(response.data.data).toString();
          console.log("queryString",queryString);
          setLoading(false)
          onCloseModal();     
          // naviagte(`/thank-you?${queryString}`) 
          window.location.href = `https://logoaura.com/thank-you?${queryString}`;
        }
        console.log("responseresponseresponse",response);
  
  
      } catch (error) {
        console.error("Error:", error);
      }

     }
          
      }
  return (
    <div className="">
      <Modal open={open} onClose={onCloseModal} center>
      <div className="modal" id="getQuote">
  <div className="modal-dialog modal-dialog-centered">
    <div className="entry-box" style={{width: "100%"}}>
      {/* <form > */}
        <div className="col-sm-12 entry-left">
          <button
            type="button"
            name="close"
            className="close"
            aria-label="close"
            data-dismiss="modal"
            onClick={onCloseModal}
          >
            ×
          </button>
          <h1>PLACE YOUR ORDER</h1>
        </div>
        <div className="col-sm-12 entry-right">
          <div className="text">
            <h2>
              <span>
                SIGN UP NOW AND GET UP TO <br />{" "}
              </span>{" "}
              70% OFF
            </h2>
            <p>
              Fill out this form and we will get <strong>back to you.</strong>
            </p>
          </div>
          <form onSubmit={onSubmitFunc}>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Your Name"
                required
                onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Email Address"
                required
                onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <input
                type="number"
                className="form-control"
                placeholder="Phone Number"
                name="phone"
                minLength={9}
                min={9}
                required
                onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
              />
              {errors && <div className="error" style={{color: "white"}}>Phone number must be at least 9 digits long</div>}

            </div>
          </div>
          <div className="col-sm-12 privicylink">
            <p style={{ marginTop: 20, fontSize: 11 }}>
              {" "}
              By clicking "Submit," you confirm that you agree to
              {/*?php echo WEBSITE_NAME ?*/}{" "}
              <a href="../privacy.php"> Privacy Policy. </a>
            </p>
          </div>
          <div className="col-sm-12">
            <div className="form-group mb-0 mt-4">
              {loading == true ? (
                 <div className="learn-more-tab themeBtn" style={{display: "inline-block", padding: "01.rem 0.5rem",margin: "0px 2px"}}>
                 <img src={logoGif} alt="gif" width={"35px"}/>
               </div>
              ): (
                <button
                type="submit"
                className="entery-submit"
                name="signupForm"
                aria-label="signupForm"
                id="signupBtn"
                // value={1}
                disabled={loading}
              >
                Place your order
              </button>
              )}

              {/* <div
                className="error mt-3 alert alert-danger text-left mb-0"
                style={{ display: "none" }}
              />
              <div
                className="success mt-3 alert alert-success text-left mb-0"
                style={{ display: "none" }}
              ></div>
              <div className="loader" style={{ display: "none" }}>
                <img alt="loader" src="loader.gif" />
              </div> */}
            </div>
          </div>
          </form>
        </div>
      {/* </form> */}
    </div>
  </div>
</div>

      </Modal>
    </div>
  );
};
