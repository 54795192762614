import React, { useEffect } from 'react'
import { logo } from '../../assest'
import { NavLink } from 'react-router-dom';
import { WEBSITE_LOGO } from '../../config';

export const Header = () => {

  useEffect(() => {
    // Function to open chat window
    const openChatWindow = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.show();
      }
    };

    // Function to toggle chat window
    const toggleChat = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.toggle();
      }
    };

    // Initialize chat and open by default
    const initializeChat = () => {
      const intervalId = setInterval(() => {
        if (window.$zopim && window.$zopim.livechat) {
          clearInterval(intervalId);
          openChatWindow();
          listenForNewMessages();
        }
      }, 100); // Check every 100ms if chat widget is available
    };

    // Listen for new messages
    const listenForNewMessages = () => {
      if (window.$zopim && window.$zopim.livechat) {
        window.$zopim.livechat.setOnUnreadMsgs((numberOfUnreadMessages) => {
          if (numberOfUnreadMessages > 0) {
            openChatWindow();
          }
        });
      }
    };

    initializeChat();

    // Attach event listeners when the component mounts
    const chatButton = document.querySelector('.zendeskchat'); // Replace with actual button selector
    if (chatButton) {
      chatButton.addEventListener('click', toggleChat);
    }

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      if (chatButton) {
        chatButton.removeEventListener('click', toggleChat);
      }
    };
  }, []);

  // useEffect(() => {
  //   // Ensure $zopim is available globally
  //   window.$zopim = window.$zopim || function (c) {
  //     (window.$zopim.q = window.$zopim.q || []).push(c);
  //   };

  //   // Wait for the zopim live chat API to be ready
  //   window.$zopim(() => {
  //     // Define the function to show chat window when there are unread messages
  //     window.$zopim.livechat.setOnUnreadMsgs((numUnread) => {
  //       if (numUnread > 0 && !window.$zopim.livechat.window.getDisplay()) {
  //         window.$zopim.livechat.window.show();
  //       }
  //     });
  //   });
  // }, []);





  return (
    <div>

      <header>
        <nav>
          <div class="container">
            <div class="row justify-content-between align-items-center">
              <div class="col-lg-4 col-md-4 col-5">
                <NavLink to="/" class="navbar-brand">
                  <img class="lazyload logo_img" src={WEBSITE_LOGO} alt="logo" />
                </NavLink>
              </div>
              <div class="col-lg-6 col-md-8 col-7">
                <ul>
                  <li>
                    <a href="tel:+9123456789">
                      <i class="fas fa-phone-alt"></i>
                      <span>Call Us:</span> +9123456789
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" class="themeBtn chat zendeskchat">Talk To Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}

