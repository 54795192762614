import React from 'react'
import { cards } from '../../assest'

export const Footer = () => {
  return (
    <div>
      <footer>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-5 col-md-5 col-12">
        <span>
          <a href="../terms.php">Terms &amp; Conditions</a> |
          <a href="../privacy.php">Privacy Policy </a> |
          <a href="../cookies-policy.php">Cookies Policy </a>
        </span>
      </div>
      <div className="col-lg-4 col-md-4 col-12 text-left">
        <p>
          Copyright © {/*?php echo date('Y'); ?*/}{" "}
          Logo Aura | All rights reserved.
        </p>
      </div>
      <div className="col-lg-3 col-md-3 col-12 text-right">
        <img
          className="img-responsive img-fluid lazyload"
          src={cards}
          alt="Brand Logo"
        />
      </div>
    </div>
    <div className="disclemar">
      <p>
        <strong>Disclaimer:</strong> The logo, name and graphics of{" "}
        logo Aura and its products &amp; services are the
        trademarks of logo Aura. All other company names,
        brand names, trademarks and logos mentioned on this website are the
        property of their respective owners and do not constitute or imply
        endorsement, sponsorship or recommendation thereof by{" "}
        logo Aura and do not constitute or imply
        endorsement, sponsorship or recommendation of{" "}
        logo Aura by the respective trademark owner.
      </p>
    </div>
  </div>
</footer>

    </div>
  )
}

