import React, {useState, useEffect} from 'react'
import { about1, about2, textcircle_01 } from '../../assest'
import { ModalSection } from '../../layout/modalSection';
import { WEBSITE_NAME } from '../../config';

export const ImpressiveLogos = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };


  // useEffect(() => {
  //   // Define the function to toggle the chat
  //   const toggleChat = () => {
  //     if (window.$zopim) {
  //       window.$zopim.livechat.window.toggle();
  //     }
  //   };

  //   // Attach event listeners when the component mounts
  //   const chatButton = document.querySelector('.zendeskchat2'); // Replace '.chat' with the appropriate button selector
  //   if (chatButton) {
  //     chatButton.addEventListener('click', toggleChat);
  //   }

  //   // Cleanup: Remove event listeners when the component unmounts
  //   return () => {
  //     if (chatButton) {
  //       chatButton.removeEventListener('click', toggleChat);
  //     }
  //   };
  // }, []);
  return (
    <div>
      <section className="aboutSec">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
        <figure className="aboutImgCont">
          <img
            className="lazyload"
            src={about1}
            alt=""
          />
          <img
            className="lazyload"
            src={about2}
            alt=""
          />
        </figure>
      </div>
      <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12">
        <div className="content">
          <div className="secHeading">
            <h2>
              <span>
                Impressive
                <img
                  className="lazyload"
                  src={textcircle_01}
                  alt=""
                />
              </span>
              {" "} Logos, Creating Everlasting Impression On Your Target Audience.
            </h2>
          </div>
          <p>
           {WEBSITE_NAME} is a digital agency that not only
            strives to provide its customers with top-notch logo design
            services, but also the best customer service that they have
            experienced. Comprising of a team of dedicated professionals, the
            team has to its credit thousands of completed project deliveries and
            more.
          </p>
          <div className="about-sec-buttons">
            <a href="javascript:;" className="themeBtn chat zendeskchat2">
              Live Chat
            </a>
            <a href="javascript:;" onClick={onOpenModal} className="themeBtn ml-4 openpopup">
              Get Quote
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ModalSection open={open} onCloseModal={onCloseModal}  />

    </div>
  )
}

