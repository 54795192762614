import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const usePageMeta = () => {
  const location = useLocation();

  useEffect(() => {
    const setMetaTags = () => {
      let title;
      let description;
      let url = window.location.href;

      switch (location.pathname) {
        case '/':
          title = 'Home Page - Custom Logo Design for Business by Logo Aura';
          description = 'Welcome to the Home Page of Logo Aura. We provide custom logo design services for businesses.';
          break;
        case '/thank-you':
          title = 'Thank You - Custom Logo Design for Business by Logo Aura';
          description = 'Thank you for visiting Logo Aura. We appreciate your business.';
          break;
        default:
          title = 'Custom Logo Design for Business by Logo Aura';
          description = 'Discover custom logo design services for businesses at Logo Aura.';
      }

      document.title = title;

      return (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
        </Helmet>
      );
    };

    setMetaTags();
  }, [location.pathname]);

  return null;
};

export default usePageMeta;
