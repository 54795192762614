import React, { useState } from "react";
import { ipad, logoGif, remedybrand, textcircle_01 } from "../../assest";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const ContactUs = () => {
  const naviagte=useNavigate();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log("formData", formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {


      const currentUrl = window.location.href;
      const protocol = window.location.protocol;  // "https:"
      const hostname = window.location.hostname;  // "logoaura.com"
      
      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`
      const queryStringFormData = new URLSearchParams(formData).toString()

      let finalReq = {
        ...formData,
        source: `https://logoaura.com/${queryStringFormData}`,
        domain: baseUrl,
        lead_url: baseUrl,
        url: `https://logoaura.com/${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = "https://tgcrm.net/api/form_submission?brand_key=594696";
        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(response.data.data).toString();
          console.log("queryString",queryString);
          setLoading(false);
          setErrors(false)
          setFormData({
            name: "",
            email: "",
            message: "",
            phone: "",
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://logoaura.com/thank-you?${queryString}`;
        }
        console.log("responseresponseresponse", response);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div>
      <section className="contactsec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="secHeading main-hdg">
                <h2>
                  <span>
                    Contact  {" "}
                    <img
                      className="lazyload"
                      src={textcircle_01}
                      alt="textcircle_01"
                    />
                  </span>
                  Us
                </h2>
              </div>
              <div className="footerform">
                <form className="form_submission" onSubmit={onSubmitFunc}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Enter Your Name"
                          required
                          onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Your Email"
                          name="email"
                          required
                          onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Phone Number"
                          name="phone"
                          minLength={9}
                          min={9}
                          required
                          onChange={(e) =>
                            setFormData({ ...formData, phone: e.target.value })
                          }
                        />
                        {errors && (
                          <div className="error">
                            Phone number must be at least 9 digits long
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <textarea
                          placeholder="Enter Message Here"
                          name="message"
                          className="form-control"
                          // defaultValue={""}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              message: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <p
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                          fontSize: 12,
                        }}
                      >
                        {" "}
                        By clicking "Submit," you confirm that you agree to
                        {/*?php echo WEBSITE_NAME ?*/}{" "}
                        <a href="../privacy.php"> Privacy Policy. </a>
                      </p>
                    </div>
                    <div className="col-sm-12">
                      {loading == true ? (
                        <div
                          className="learn-more-tab themeBtn"
                          style={{
                            display: "inline-block",
                            padding: "01.rem 0.5rem",
                            margin: "0px 2px",
                          }}
                        >
                          <img src={logoGif} alt="gif" width={"35px"} />
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="themeBtn"
                          id="signupBtn"
                        >
                          SUBMIT NOW
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pix-fancy-mockup">
                <div className="pix-fancy-content">
                  <img src={remedybrand} className="lazyload" alt="" />
                </div>
                <img
                  className="pix-fancy-device-img lazyload"
                  src={ipad}
                  alt="ipad"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
