import React, {useState, useEffect} from 'react'
import { ModalSection } from '../../layout/modalSection';

export const BannerChat = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

//   useEffect(() => {
//     // Define the function to toggle the chat
//     const toggleChat = () => {
//         if (window.$zopim) {
//             window.$zopim.livechat.window.show();
//         }
//     };

//     // Open chat by default when the component mounts with a delay
//     // toggleChat();
//     // Attach event listeners when the component mounts
//     const chatButton = document.querySelector('.zendeskchat3'); // Replace '.zendeskchat3' with the appropriate button selector
//     if (chatButton) {
//         chatButton.addEventListener('click', toggleChat);
//     }

//     // Cleanup: Remove event listeners when the component unmounts
//     return () => {
//         if (chatButton) {
//             chatButton.removeEventListener('click', toggleChat);
//         }
//     };
// }, []);



  return (
    <div>
      <section className="cta-sec lazy-background section-padding">
  <div className="container">
    <div className="cta-contains lazy-background">
      <h1>
        Designing a great logo is hard. <span> We make it easy. </span>
      </h1>
      <h2>
        Call our assistant at{" "}
        <a href="<?php echo PHONE_HREF ?>">{/*?php echo PHONE ?*/}</a>
      </h2>
      <div className="cta-buttons">
        <a href="javascript:;" className="themeBtn chat zendeskchat3">
          Live Chat
        </a>
        <a href="javascript:;" onClick={onOpenModal} className="themeBtn ml-4 openpopup">
          Get Quote
        </a>
      </div>
    </div>
  </div>
</section>
<ModalSection open={open} onCloseModal={onCloseModal}  />

    </div>
  )
}

