import React, { useState } from 'react';
import './App.css';
import { Header } from './layout';
import { Footer } from './layout/footer';
import { Banner, BannerChat, ContactUs, HappyClient, HowItWorks, ImpressiveLogos, LogoInfinite, OurPortfolio, OurServices, PortfolioComp } from './module';
import { ThankYou } from './module/thankyou';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { OurPricing } from './module/ourPricing';
import { Home } from './layout/pages';
import usePageTitle from './usePageTitle';



function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/thank-you' element={<ThankYou />} />
        </Routes>
      </BrowserRouter>

      {/* <Header/>
       <Banner/>
       <PortfolioComp/>
       <ImpressiveLogos/>
       <OurServices/>
       <BannerChat/>
       <OurPortfolio/>
       <OurPricing/>
       <LogoInfinite/>
       <HappyClient/>
       <HowItWorks/>
       <ContactUs/>
       <Footer/> */}

    </div>
  );
}

export default App;
